




















































































































import type { AuthFormModal, AuthFormErrors, AuthErrorModal, LoginTypeModal, VForm } from '@homestyle/shared-data';
import { mdiGoogle } from '@mdi/js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { EventBusService } from '../../services/event-bus.service';
import { AuthEvents, AuthEventLimit } from '../../utils/auth-event.enum';
import { AppEvents } from '../../utils/app-event.enum';
import { Once } from '../../utils/once.decorator';

@Component({})
export class AuthForm extends Vue {
	public createUser = false;
	public emailRules = [
		(v) => !!v || 'Username is required',
		(v) => /.+@.+\..+/.test(v) || 'Username must be a valid E-Mail',
	];
	@Prop() errors: AuthFormErrors;
	public google = mdiGoogle;
	public loginForm: AuthFormModal = {
		password: '',
		userName: '',
	};
	public passwordRules = [
		(v) => /(?=.{8,})/.test(v) || 'The Password is too short. Please 8 characters or more.',
		(v) => /(?=.*[a-z])/.test(v) || 'The Password must contain at least 1 lowercase alphabetical character',
		(v) => /(?=.*[A-Z])/.test(v) || 'The Password must contain at least 1 uppercase alphabetical character',
		(v) => /(?=.*[0-9])/.test(v) || 'The Password must contain at least 1 number',
	];
	public show = false;
	public showConfirm = false;
	public signupForm: AuthFormModal = {
		confirmPassword: '',
		password: '',
		userName: '',
	};
	private form: VForm;

	@Watch('loginForm', { deep: true })
	public getLogin() {
		const valid = this.form.validate();
		if (valid) {
			EventBusService.emit({
				event: AuthEvents.form,
				payload: { userName: this.loginForm.userName, password: btoa(this.loginForm.password) },
			});
		}
	}

	@Watch('signupForm', { deep: true })
	public getSignup() {
		console.log('google.com');
		EventBusService.emit({
			event: AuthEvents.form,
			payload: { userName: this.signupForm.userName, password: btoa(this.signupForm.password) },
		});
	}

	public googleSignin() {
		const loginType: LoginTypeModal = {
			useGoogle: true,
		};
		EventBusService.emit({ event: AuthEvents.google, payload: loginType });
	}

	public created() {
		EventBusService.on(AuthEvents.close, () => this.resetModal());
		EventBusService.on(AppEvents.complete, () => this.resetModal());
	}

	public mounted() {
		this.form = this.$refs.authForm as VForm;
	}

	public get newUserError() {
		const userNameState = this.errors && this.errors.newUser;
		let userError: AuthErrorModal = {
			error: false,
			message: '',
		};
		if (userNameState) {
			userError = userNameState;
		}

		return userError;
	}

	public toggleNewUser() {
		this.createUser = !this.createUser;
		EventBusService.emit({ event: AuthEvents.create, payload: this.createUser });
		this.clearForm();
	}

	public get userNameError() {
		const userNameState = this.errors && this.errors.userName;
		let userError: AuthErrorModal = {
			error: false,
			message: '',
		};
		if (userNameState) {
			userError = this.errors.userName;
		}

		return userError;
	}

	public get validatePass() {
		const isMatch = this.signupForm.confirmPassword === this.signupForm.password;

		return isMatch ? '' : 'Passwords do not match.';
	}

	private clearForm() {
		if (this.form) {
			this.form.reset();
			this.form.resetValidation();
		}
	}

	@Once(AuthEventLimit.close)
	private resetModal() {
		this.clearForm();
		this.createUser = false;
		EventBusService.emit({ event: AuthEvents.create, payload: this.createUser });
	}
}

export default AuthForm;
