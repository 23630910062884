







































export default {
	data: () => {
		return {};
	},
	props: {
		isAuthed: Boolean,
	},
};
