

















































import type { AuthFormModal, LoginTypeModal } from '@homestyle/shared-data';
import { UserRoles } from '@homestyle/shared-data';
import { useStore } from 'vuex-simple';

import AccessMessage from '../shared/components/AccessMessage';
import AuthForm from '../shared/components/AuthForm';
import { EventBusService } from '../shared/services/event-bus.service';
import type { EventBus } from '../shared/services/event-bus.service';
import { AuthEvents, AuthMessages } from '../shared/utils/auth-event.enum';
import { AppEvents } from '../shared/utils/app-event.enum';
import { MyStore } from '../store';

export default {
	components: {
		AccessMessage,
		AuthForm,
	},
	data() {
		const create = false;
		const store = useStore<MyStore>(this.$store);

		return {
			create,
			store,
		};
	},
	metaInfo: {
		title: 'User Login',
	},
	computed: {
		formErrors() {
			const store: MyStore = this.store;
			return store.formErrors;
		},
		loggedIn() {
			const store: MyStore = this.store;

			return store.currentUser.role !== UserRoles.Anonymous;
		},
	},
	created() {
		const store: MyStore = this.store;

		EventBusService.on(AppEvents.complete, (completeEvt) => {
			const msg = completeEvt.payload as string;

			if (msg === AuthMessages.created) {
				this.create = false;
			}
		});

		EventBusService.on(AuthEvents.create, (createEvt) => {
			this.create = createEvt.payload as boolean;
		});

		EventBusService.on(AuthEvents.form, (formEvt) => {
			const formData = formEvt.payload as AuthFormModal;

			store.commitAuthForm(formData);
			store.dispatchEmailCheck({ email: formData.userName });
		});

		EventBusService.on(AuthEvents.google, (loginEvt) => {
			store.dispatchAuthLogin(loginEvt.payload as LoginTypeModal);
			EventBusService.emit({ event: AuthEvents.close });
		});
	},
	methods: {
		closeModal(): void {
			EventBusService.emit({ event: AuthEvents.close });
		},
		emailLogin() {
			const loginEvt: EventBus = {
				event: AuthEvents.google,
				payload: {
					isGoogle: false,
				},
			};

			EventBusService.emit(loginEvt);
		},
		newUser() {
			const store: MyStore = this.store;
			store.dispatchCreateUser();
		},
	},
};
