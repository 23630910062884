import { render, staticRenderFns } from "./AuthPage.vue?vue&type=template&id=77f8ac5c&scoped=true&lang=html&"
import script from "./AuthPage.vue?vue&type=script&lang=ts&"
export * from "./AuthPage.vue?vue&type=script&lang=ts&"
import style0 from "./AuthPage.vue?vue&type=style&index=0&id=77f8ac5c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f8ac5c",
  null
  
)

export default component.exports